import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Divider,
  FormControl,
  Grid, IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField, Typography
} from '@material-ui/core';
import { AttachFile, Delete } from '@material-ui/icons';

import { MAIN_TITLE } from '../../assets/styles/variables';
import Loader from '../../components/Loader';
import TransferList from '../../components/TransferList';
import { del, get, post, put } from '../../shared/api';
import { CONTAINER_STATUSES, ROLE_ADMIN, ROLE_WAREHOUSE } from '../../shared/contants';
import { getDateFormValue, getErrorMessage } from '../../shared/helpers';
import { selectUser } from '../../store/selectors/auth.selectors';

import styles from '../Login/components/LoginForm.module.scss';
import galleryStyles from '../OrderForm/OrderForm.module.scss';

const ContainerForm = () => {
  const { addToast } = useToasts();
  const { control, handleSubmit, reset } = useForm();
  const [containerToEdit, setContainerToEdit] = useState(null);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [currentContainerLoadedVehicles, setCurrentContainerLoadedVehicles] = useState(null);
  const [areVehiclesLoading, setAreVehiclesLoading] = useState(false);
  const [shippingLines, setShippingLines] = useState([]);
  const [fileToDeleteType, setFileToDeleteType] = useState('');
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [billOfLadingFile, setBillOfLadingFile] = useState(null);
  const [releaseDocumentFile, setReleaseDocumentFile] = useState(null);
  const currentUser = useSelector(selectUser);
  const [errorMessage, setErrorMessage] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const history = useHistory();
  const [imageToDelete, setImageToDelete] = useState('');
  const [vehicleImages, setVehicleImages] = useState([]);
  const [isAddImageDialogOpen, setIsAddImagesDialogOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState(null);
  const [errorMessageUpload, setErrorMessageUpload] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const loadVehicles = () => {
    setAreVehiclesLoading(true);

    let vehiclesUrl = '/vehicles/my';
    if (currentUser.role === ROLE_ADMIN) {
      vehiclesUrl = '/vehicles/container';
    } else if (currentUser.role === ROLE_WAREHOUSE) {
      vehiclesUrl = `/vehicles/warehouse/${currentUser.email}`;
    }

    get(vehiclesUrl)
      .then((vehiclesData) => {
        if (Array.isArray(vehiclesData)) {
          setVehicles(vehiclesData);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setAreVehiclesLoading(false);
      });
  }

  const loadShippingLines = async () => {
    await get('/shipping-lines')
      .then((data) => {
        if (Array.isArray(data)) {
          data.map(containerData => ({
            ...containerData,
            expectedDate: getDateFormValue(data.expectedDate),
          }))

          setShippingLines(data);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });
  }

  useEffect(() => {
    loadShippingLines();
    loadVehicles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      get(`/containers/id/${id}`)
        .then((containerData) => {
          if (containerData && containerData.id) {
            setCurrentContainerLoadedVehicles(containerData.vehicleIds);
            setContainerToEdit(containerData);
            reset({
              ...containerData,
              shippingLine: containerData?.shippingLine?.id || '',
              status: CONTAINER_STATUSES.find(status => status.value === containerData?.status)?.value,
            },
            );
            loadImages();
          }
        })
        .catch((error) => {
          addToast(getErrorMessage(error), { appearance: 'error' });
        })
        .finally(() => {
          setIsEditLoading(false);
        });
    } else {
      reset({ name: '', expectedDate: '', shippingLine: '', status: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const setLoadedVehicles = (vehicleVins) => {
    setCurrentContainerLoadedVehicles(
      vehicles
        .filter(vehicle => vehicleVins.includes(vehicle.vin))
        .map(vehicle => vehicle.vehicleId)
    );
  };

  const goBack = (event) => {
    event.preventDefault();

    history.goBack();
  };

  const closeImageUploadDialog = () => {
    setIsAddImagesDialogOpen(false);
    setCurrentImages(null);
    setErrorMessageUpload('');
  };

  const editContainer = async (data) => {
    setIsEditLoading(true);

    const {
      id,
      expectedDate,
      name,
      shippingLine,
      status,
    } = data;

    let formData = {
      name,
      status,
      vehicleIds: currentContainerLoadedVehicles,
      username: currentUser.email,
    };

    if (shippingLine) {
      formData = {
        ...formData,
        shippingLineId: shippingLine,
      };
    }

    if (expectedDate) {
      formData = {
        ...formData,
        expectedDate,
      };
    }

    if (id) {
      await put(`/containers/${id}`, formData)
        .then((response) => {
          if (response) {
            setErrorMessage('');
            addToast(
              'Container updated successfully!',
              { appearance: 'success' }
            );

            const fileRequests = [];

            if (billOfLadingFile) {
              let fileUploadFormData = new FormData();
              fileUploadFormData.append('files', billOfLadingFile);
              fileUploadFormData.append('typeOfFile', 'BillOfLading');

              for (let index = 0; index < formData.vehicleIds?.length; index++) {
                fileUploadFormData.append('vehicleIds[]', formData.vehicleIds[index]);
              }

              fileRequests.push(
                post(
                  `/pdf/upload/multiple`,
                  fileUploadFormData,
                  { headers: { 'Content-Type': 'multipart/form-data' } }
                )
              );
            } else {
              fileRequests.push(setTimeout(() => null, 0));
            }

            if (releaseDocumentFile) {
              let fileUploadFormData = new FormData();
              fileUploadFormData.append('files', releaseDocumentFile);
              fileUploadFormData.append('typeOfFile', 'ReleaseDocuments');

              for (let index = 0; index < formData.vehicleIds?.length; index++) {
                fileUploadFormData.append('vehicleIds[]', formData.vehicleIds[index]);
              }

              fileRequests.push(
                post(
                  `/pdf/upload/multiple`,
                  fileUploadFormData,
                  { headers: { 'Content-Type': 'multipart/form-data' } }
                )
              );
            } else {
              fileRequests.push(setTimeout(() => null, 0));
            }

            axios.all(fileRequests).then(axios.spread((...responses) => {
              if (responses[0]) {
                setBillOfLadingFile(null);
                addToast('Bill of lading successfully updated!', { appearance: 'success' });
              }

              if (responses[1]) {
                setReleaseDocumentFile(null);
                addToast('Release documents successfully updated!', { appearance: 'success' });
              }

            })).catch(errors => {
              console.log(errors);
              addToast(getErrorMessage(errors), { appearance: 'error' });
            }).finally(() => {
              loadVehicles();
            });
          }
        })
        .catch((error) => {
          setErrorMessage(getErrorMessage(error));
        })
        .finally(() => {
          setIsEditLoading(false);
        });
    } else {
      await post(`/containers`, formData)
        .then((response) => {
          if (response) {
            setErrorMessage('');
            addToast(
              'Container created successfully!',
              { appearance: 'success' }
            );

            const fileRequests = [];

            if (billOfLadingFile) {
              let fileUploadFormData = new FormData();
              fileUploadFormData.append('files', billOfLadingFile);
              fileUploadFormData.append('typeOfFile', 'BillOfLading');

              for (let index = 0; index < formData.vehicleIds?.length; index++) {
                fileUploadFormData.append('vehicleIds[]', formData.vehicleIds[index]);
              }

              fileRequests.push(
                post(
                  `/pdf/upload/multiple`,
                  fileUploadFormData,
                  { headers: { 'Content-Type': 'multipart/form-data' } }
                )
              );
            } else {
              fileRequests.push(setTimeout(() => null, 0));
            }

            if (releaseDocumentFile) {
              let fileUploadFormData = new FormData();
              fileUploadFormData.append('files', releaseDocumentFile);
              fileUploadFormData.append('typeOfFile', 'ReleaseDocuments');

              for (let index = 0; index < formData.vehicleIds?.length; index++) {
                fileUploadFormData.append('vehicleIds[]', formData.vehicleIds[index]);
              }

              fileRequests.push(
                post(
                  `/pdf/upload/multiple`,
                  fileUploadFormData,
                  { headers: { 'Content-Type': 'multipart/form-data' } }
                )
              );
            } else {
              fileRequests.push(setTimeout(() => null, 0));
            }

            axios.all(fileRequests).then(axios.spread((...responses) => {
              if (responses[0]) {
                setBillOfLadingFile(null);
                addToast('Bill of lading successfully updated!', { appearance: 'success' });
              }

              if (responses[1]) {
                setReleaseDocumentFile(null);
                addToast('Release documents successfully updated!', { appearance: 'success' });
              }
            })).catch(errors => {
              console.log(errors);
              addToast(getErrorMessage(errors), { appearance: 'error' });
            }).finally(() => {
              loadVehicles();
            });
          }
        })
        .catch((error) => {
          setErrorMessage(getErrorMessage(error));
        })
        .finally(() => {
          setIsEditLoading(false);
        });
    }
  };

  const uploadImageInChunks = async (file, chunkSize = 50 * 1024 * 1024) => { // 5MB chunks
    const totalChunks = Math.ceil(file.size / chunkSize);
    const uploadId = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`; // Unique upload ID
  
    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const start = chunkIndex * chunkSize;
      const end = Math.min(file.size, start + chunkSize);
      const chunk = file.slice(start, end);
  
     const formData = new FormData();
     formData.append('chunk', chunk); // Use 'chunk' to match the backend's parameter
     formData.append('chunkIndex', chunkIndex);
     formData.append('totalChunks', totalChunks);
     formData.append('uploadId', uploadId);
     formData.append('fileName', file.name); 
  
      try {
        // Include vehicleId in the URL
        await post(`/images/upload-chunk/${id}?chunk=${chunk}&chunkIndex=${chunkIndex}&totalChunks=${totalChunks}&uploadId=${uploadId}&fileName=${file.name}`, formData);
      } catch (error) {
        console.error(`Error uploading chunk ${chunkIndex}:`, error);
        throw error; // Propagate the error to the caller
      }
    }
  };
  
  const uploadImage = () => {
    setIsLoading(true);
    setErrorMessageUpload('');
  
    if (currentImages && currentImages.length) {
      Promise.all(
        currentImages.map((image) => uploadImageInChunks(image))
      )
        .then(() => {
          setIsAddImagesDialogOpen(false);
          setCurrentImages(null);
          addToast('Images uploaded successfully!', { appearance: 'success' });
          loadImages(); // Reload images after upload
        })
        .catch((error) => {
          console.error('Error uploading images:', error);
          setErrorMessageUpload('Error occurred while uploading images.');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setErrorMessageUpload('You must select an image!');
    }
  };
  
  
  const loadImages = () => {
    get(`/images/get/${id}`)
      .then((images) => {
        if (images && Array.isArray(images)) {
          setVehicleImages(images.filter(image => !!image));
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });
  };

  const selectImages = (event) => {
    const numberOfSelectedImages = event.target.files?.length || 0;
    const imagesToUpload = [];

    if (numberOfSelectedImages) {
      for (let index = 0; index < numberOfSelectedImages; index++) {
        imagesToUpload.push(event.target.files[index]);
      }
    }

    if (imagesToUpload.length) {
      setCurrentImages(imagesToUpload);
    }
  };

  const deleteImage = () => {
    del(`/images/${id}/${imageToDelete}`)
      .then((response) => {
        if (response) {
          addToast(response, { appearance: 'success' });
          setImageToDelete('');
          loadImages();
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });
  };

  const deleteAllImages = () => {
    del(`/images/deleteAll/${id}`)
      .then((response) => {
        if (response) {
          addToast(response, { appearance: 'success' });
          loadImages();
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });
  };

  // File upload
  const selectBOLFile = (event) => {
    setBillOfLadingFile(event.target.files[0]);
  };

  const selectReleaseDocumentsFile = (event) => {
    setReleaseDocumentFile(event.target.files[0]);
  };

  // File delete
  const deleteFile = () => {
    if (!fileToDelete) {
      return;
    }

    const {
      billOfLading = {},
      releaseDocuments = {},
      vehicleIds = [],
    } = fileToDelete;

    const { title } = fileToDeleteType === 'BOL' ? billOfLading : releaseDocuments;

    if (!title) {
      return;
    }

    setIsDeleteLoading(true);

    put(`/pdf/delete/multiple`, { title, vehicleIds })
      .then((response) => {
        if (response) {
          addToast('File removed successfully!', { appearance: 'success' });

          if (fileToDeleteType === 'BOL') {
            setBillOfLadingFile(null);
          } else {
            setReleaseDocumentFile(null);
          }

          closeDeleteFileDialog();
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsDeleteLoading(false);
      });
  };

  const closeDeleteFileDialog = () => {
    setFileToDelete(null);
    setFileToDeleteType('');
  };

  return (
    <>
      <Helmet>
        <title>Container {id ? 'edit' : 'create'} | {MAIN_TITLE}</title>
      </Helmet>
      <Container component="main">
        <Card>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {id ? 'Edit' : 'Create new'} container
            </Typography>
            <Divider />
            <br />
            <form onSubmit={handleSubmit(editContainer)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Container number"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="status" variant="outlined" required>Status</InputLabel>
                    <Controller
                      name="status"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="status"
                          label="Status"
                          variant="outlined"
                          required
                          fullWidth
                        >
                          {CONTAINER_STATUSES.map(({ key, label, value }) => (
                            <MenuItem key={`status-${key}`} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={9}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="shippingLine" variant="outlined">Shipping line</InputLabel>
                    <Controller
                      name="shippingLine"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="shippingLine"
                          label="Shipping line"
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem key="shipping-line-none" value="">-- Clear --</MenuItem>
                          {shippingLines.map(({ id, name, trackUrl }) => (
                            <MenuItem key={`shipping-line-${id}`} value={id}>
                              {name} ({trackUrl})
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="expectedDate"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Expected date"
                        type="date"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                {containerToEdit && (
                  <Grid item xs={12} md={6}>
                    {containerToEdit.id ?
                      containerToEdit.billOfLading ? (
                        <div style={{ padding: '6px 0 0 4px' }}>
                          <span>Bill of lading:&nbsp;</span>
                          <IconButton
                            component="a"
                            href={containerToEdit.billOfLading?.url || containerToEdit.billOfLading}
                            rel="noopener noreferrer"
                            target="_blank"
                            color="primary"
                            area-label="download"
                            size="small"
                            title={containerToEdit.billOfLading?.title || containerToEdit.billOfLading}
                          >
                            <AttachFile fontSize="small" />
                          </IconButton>
                          &nbsp;
                          <IconButton
                            aria-label="delete"
                            type="button"
                            size="small"
                            onClick={() => {
                              setFileToDeleteType('BOL');
                              setFileToDelete(containerToEdit);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      ) : (
                        <label htmlFor="btn-upload">
                          <input
                            id="btn-upload"
                            name="btn-upload"
                            style={{ display: 'none' }}
                            type="file"
                            onChange={selectBOLFile}
                          />
                          <Button
                            className="btn-choose"
                            variant="outlined"
                            component="span"
                            fullWidth
                            style={{ minHeight: '37px' }}
                          >
                            {billOfLadingFile
                              ? (
                                <span style={{
                                  width: '100%',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}>
                                  BoL: {billOfLadingFile.name}
                                </span>
                              )
                              : 'Upload "Bill of lading"'}
                          </Button>
                        </label>
                      )
                      : (
                        <label htmlFor="btn-upload">
                          <input
                            id="btn-upload"
                            name="btn-upload"
                            style={{ display: 'none' }}
                            type="file"
                            onChange={selectBOLFile}
                          />
                          <Button
                            className="btn-choose"
                            variant="outlined"
                            component="span"
                            fullWidth
                            style={{ minHeight: '37px' }}
                          >
                            {billOfLadingFile
                              ? (
                                <span style={{
                                  width: '100%',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}>
                                  BoL: {billOfLadingFile.name}
                                </span>
                              )
                              : 'Upload "Bill of lading"'}
                          </Button>
                        </label>
                      )}
                  </Grid>
                )}
                {containerToEdit && (
                  <Grid item xs={12} md={6}>
                    {containerToEdit.id ?
                      containerToEdit.releaseDocuments ? (
                        <div style={{ padding: '6px 0 0 4px' }}>
                          <span>Release Documents:&nbsp;</span>
                          <IconButton
                            component="a"
                            href={containerToEdit.releaseDocuments?.url || containerToEdit.releaseDocuments}
                            rel="noopener noreferrer"
                            target="_blank"
                            color="primary"
                            area-label="download"
                            size="small"
                            title={containerToEdit.releaseDocuments?.title || containerToEdit.releaseDocuments}
                          >
                            <AttachFile fontSize="small" />
                          </IconButton>
                          &nbsp;
                          <IconButton
                            aria-label="delete"
                            type="button"
                            size="small"
                            onClick={() => {
                              setFileToDeleteType('ReleaseDocuments');
                              setFileToDelete(containerToEdit);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      ) : (
                        <label htmlFor="btn-upload-release-documents">
                          <input
                            id="btn-upload-release-documents"
                            name="btn-upload-release-documents"
                            style={{ display: 'none' }}
                            type="file"
                            onChange={selectReleaseDocumentsFile}
                          />
                          <Button
                            className="btn-choose"
                            variant="outlined"
                            component="span"
                            fullWidth
                            style={{ minHeight: '37px' }}
                          >
                            {releaseDocumentFile
                              ? (
                                <span style={{
                                  width: '100%',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}>
                                  Release Documents: {releaseDocumentFile.name}
                                </span>
                              )
                              : 'Upload "Release Documents"'}
                          </Button>
                        </label>
                      )
                      : (
                        <label htmlFor="btn-upload-release-documents">
                          <input
                            id="btn-upload-release-documents"
                            name="btn-upload-release-documents"
                            style={{ display: 'none' }}
                            type="file"
                            onChange={selectReleaseDocumentsFile}
                          />
                          <Button
                            className="btn-choose"
                            variant="outlined"
                            component="span"
                            fullWidth
                            style={{ minHeight: '37px' }}
                          >
                            {releaseDocumentFile
                              ? (
                                <span style={{
                                  width: '100%',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}>
                                  Release Documents: {releaseDocumentFile.name}
                                </span>
                              )
                              : 'Upload "Release Documents"'}
                          </Button>
                        </label>
                      )}
                  </Grid>
                )}
                {(containerToEdit && containerToEdit.vehicleIds && vehicles.length) ? (
                  <Grid item xs={12}>
                    <TransferList
                      leftLabel="Vehicles"
                      rightLabel="Loaded vehicles"
                      initialLeftItems={vehicles.filter(v => !(v.container && v.container.name)).map(v => v.vin)}
                      initialRightItems={vehicles.filter(v => containerToEdit.vehicleIds.includes(v.vehicleId)).map(v => v.vin)}
                      handleChanged={setLoadedVehicles}
                    />
                  </Grid>
                ) : null}
                {errorMessage && (
                  <Grid item xs={12}>
                    <Typography color="error" variant="body2">
                      {errorMessage}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {id && (
                <>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} className="gallery">
                        <Grid container>
                          <Grid item xs={8}>
                            <Typography variant="h6" gutterBottom>
                              Gallery
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Box textAlign="right">
                              <Button
                                onClick={() => setIsAddImagesDialogOpen(true)}
                                variant="contained"
                                color="primary"
                                style={{ marginRight: '10px' }} // Add some margin between the buttons
                              >
                                Add images
                              </Button>
                              <Button
                                onClick={deleteAllImages}
                                variant="contained"
                                color="secondary"
                              >
                                Delete all images
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      {vehicleImages.length ? (
                        vehicleImages.map((imageData, index) => (
                          <Grid item xs={12} sm={6} md={3} key={`image-${index}`}>
                            <div className={galleryStyles.imageWrapper}>
                              <div className={galleryStyles.removeImageButton}>
                                <IconButton
                                  aria-label="delete"
                                  type="button"
                                  className={galleryStyles.remove}
                                  onClick={() => setImageToDelete(imageData?.title || imageData)}
                                >
                                  <Delete />
                                </IconButton>
                              </div>
                              <img
                                style={{ width: '100%' }}
                                src={imageData?.url || imageData}
                                alt={`preview-${index}`}
                              />
                            </div>
                          </Grid>
                        ))
                      ) : (
                        <Grid item xs={12}>
                          <Typography variant="body2" gutterBottom>
                            No images
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Grid
                  container
                  justify={history.length > 1 ? 'space-between' : 'flex-end'}
                  style={{ paddingTop: '10px' }}
                >
                  {history.length > 1 && (
                    <Button
                      className={styles.backButton}
                      variant="outlined"
                      onClick={goBack}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    className={styles.submitButton}
                  >
                    {id ? 'Save' : 'Create container'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
        <Dialog
          open={isAddImageDialogOpen}
          onClose={closeImageUploadDialog}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle>Add images</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <label htmlFor="contained-button-file">
                <label htmlFor="btn-upload-images">
                  <input
                    id="btn-upload-images"
                    name="btn-upload-images"
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={selectImages} />
                  <Button className="btn-choose" variant="outlined" component="span">
                    Choose Image
                  </Button>
                </label>
                <br />
                <br />
                <Typography variant="body2" gutterBottom>
                  {currentImages && currentImages.length ? (
                    currentImages.map((image) => <div key={`image-name-${image.name}`}>{image.name}</div>)
                  ) : (
                    'No images selected'
                  )}
                </Typography>
                {errorMessageUpload && (
                  <Typography color="error" variant="body2">
                    {errorMessageUpload}
                  </Typography>
                )}
              </label>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => uploadImage()} color="primary">
              Save
            </Button>
            <Button onClick={closeImageUploadDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!!imageToDelete}
          onClose={() => setImageToDelete('')}
        >
          <DialogTitle>Delete image</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this image?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteImage} color="primary">
              Confirm
            </Button>
            <Button onClick={() => setImageToDelete('')} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!!fileToDelete}
          onClose={closeDeleteFileDialog}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle>Delete file from container</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to remove the attached file from the container?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteFile} color="primary">
              Confirm
            </Button>
            <Button onClick={closeDeleteFileDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      {(isLoading || isDeleteLoading || isEditLoading || areVehiclesLoading) && <Loader />}
    </>
  );
};

export default ContainerForm;
